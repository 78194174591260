<template>
    <div class="panel">
      <section>
        <Board v-for="(board, index) in boards"
            :key="index"
            :board="board" />
      </section>
      <!-- <button class="btn" v-if="boards.filter(b => b.hide).length" v-on:click="showHidden">Restaurar tableros ocultos</button> -->
    </div>
</template>

<script>
import Board from '@/components/Board.vue'

export default {
  name: 'Panel',
  props: {
    boards: Array
  },
  components: {
    Board
  },
  computed: {
    posts () {
      return this.$store.state.posts
    }
  },
  methods: {
    showHidden () {
      this.$store.commit('SET_BOARDSSHOWALL')
    }
  }
}
</script>

<style lang="scss">
.panel{
    h1{
        color: red;
    }
    button {
      display: block;
      width: 100%;
      border: 1px solid #ccc;
      background: none;
      padding: 8px 40px;
      margin: 0 0 30px;
      cursor: pointer;
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      color: #333333CC;
    }
}
</style>
