<template>
    <div class="c-login">
        <a :href="this.$store.getters.getUser('url')" target="_blank"><img class="c-login__user" :src="this.$store.getters.getUser('avatarUrl') + '/170.png'" :alt="this.$store.getters.getUser('fullName')" /></a>
        <!-- <button v-on:click="logout">Cerrar sesión</button> -->
    </div>
</template>

<script>
export default {
  name: 'Login',
  methods: {
    logout () {
      localStorage.clear()
      location.href = '/'
    }
  }
}
</script>

<style lang="scss">
.c-login{
    display: flex;
    justify-content: flex-end;

    .c-login__user{
        width: 76px;
        height: 76px;
        cursor: pointer;
        border-radius: 50%;
    }
}
</style>
