<template>
    <article class="task vld-parent" :data-id="task.id" :data-due="moment(task.due).format('YYYY-MM-DD')" v-if="getCurrentList().selected">
        <loading v-model:active="isLoading" :is-full-page="false" />
        <span class="task__arrow">
          <img :src="require('@/assets/icons/arrow.svg')" class="arrow rotate180" />
          <div class="task__pop popup">
            <ul>
              <li><span v-on:click="goUrl">Ver en Trello</span></li>
              <li>
                <br />
                <strong>Mover a</strong>
                <ul><li v-for="list in this.board.lists" :key="list.id"><span v-on:click="moveTo(list.id)" :class="{ 'strong': task.idList == list.id }">{{list.name}}</span></li></ul>
                <br />
              </li>
              <li><span v-on:click="archive">Archivar</span></li>
            </ul>
          </div>
        </span>
        <p class="task__title"><span v-if="!edit">{{task.name}} <img :src="require('@/assets/icons/edit.svg')" v-on:click="edit=!edit" /></span><input v-model="taskName" v-on:blur="setName" v-on:keyup.enter="setName" v-if="edit" /></p>
        <p :class="'task__expiration ' + (moment(task.due).isBefore(moment(), 'd') ? 'red ' : '') + (moment(task.due).isSame(moment(), 'd') ? 'orange ' : '') + (task.dueComplete ? 'green ' : '')" v-if="task.due">
          <i class="task__expiration__icon"></i>
          <span class="task__expiration__date">
            {{moment(task.due).format('ddd DD')}} <input type="checkbox" :model="task.dueComplete" :checked="task.dueComplete" @change="dueComplete" />
          </span>
        </p>
        <div class="task__members">
          <span v-for="(member, index) in task.idMembers" class="task__member" :key="index" v-on:click="removeMember(member)" :title="member">
            <img class="task__member__image" v-if="this.$store.getters.getMember(member) && this.$store.getters.getMember(member).avatarUrl" :src="this.$store.getters.getMember(member).avatarUrl + '/170.png'" :title="this.$store.getters.getMember(member).fullName" />
            <span v-if="!this.$store.getters.getMember(member)">{{member}}</span>
          </span>
          <span class="task__member" v-on:click="showPopMembers=!showPopMembers"><span>+</span></span>
        </div>
        <div class="task__members popup" v-if="showPopMembers">
          <span v-for="member in this.board.memberships.map(i => i.idMember).filter(x => !task.idMembers.includes(x))" class="task__member" :key="member" v-on:click="addMember(member)" :title="member">
            <img class="task__member__image" v-if="this.$store.getters.getMember(member) && this.$store.getters.getMember(member).avatarUrl" :src="this.$store.getters.getMember(member).avatarUrl + '/170.png'" :title="this.$store.getters.getMember(member).fullName" />
            <span v-if="!this.$store.getters.getMember(member)">{{member}}</span>
          </span>
        </div>
    </article>
</template>

<script>
import moment from 'moment'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Task',
  data () {
    return {
      isLoading: false,
      showPopMembers: false,
      edit: false,
      name: ''
    }
  },
  components: {
    Loading
  },
  computed: {
    taskName: {
      get () { return this.task.name },
      set (value) { this.name = value }
    }
  },
  props: {
    id: String,
    task: Object,
    board: Object
  },
  created () {
    if (!this.task.name) this.edit = true
  },
  methods: {
    getCurrentList () {
      return this.board.lists ? this.board.lists.find(l => l.id === this.task.idList) : false
    },
    moment (d, p) {
      return moment(d, p)
    },
    goUrl () {
      window.open(this.task.url, '_blank')
    },
    setName () {
      this.setCard({ name: this.name || this.task.name })
    },
    addMember (id) {
      this.isLoading = true
      this.showPopMembers = false
      this.setCard({ addMember: id })
    },
    removeMember (id) {
      this.isLoading = true
      this.showPopMembers = false
      this.setCard({ removeMember: id })
    },
    dueComplete (e) {
      this.setCard({ dueComplete: e.target.checked })
    },
    archive () {
      this.setCard({ closed: true })
    },
    moveTo (id) {
      this.isLoading = true
      this.setCard({ idList: id })
    },
    setCard (data) {
      data.id = this.task.id
      this.$store.dispatch('setCard', data).then(() => {
        this.edit = false
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
.task{
  padding: 12px 14px 8px 14px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 8px;
  cursor: move;
  transition: all .3s;
  .task__arrow {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    .arrow{
      filter: invert(100%);
      &.rotate180{
        transform: rotate(180deg);
      }
    }
    .task__pop {
      display: none;
      width: 100px;
      padding: 8px !important;
      text-align: right;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          ul {
            li {
              font-size: 12px;
            }
          }
        }
      }
      span {
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
    }
    &:hover {
      .task__pop {
        display: inline-block;
      }
    }
  }
  &:hover {
    .task__arrow {
      display: inline-block;
    }
  }
  .task__title{
    text-align: left;
    color: #333333;
    display: block;
    margin:0 0 10px 0;
    padding:0;
    &:hover {
      img {
        display: inline-block;
      }
    }
    * {
      font-family: 'Open Sans', 'Helvetica';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0px;
    }
    span {
      display: inline-block;
      width: 100%;
    }
    img {
      display: none;
      height: 10px;
      cursor: pointer;
    }
    input {
      width: 100%;
      background: none;
      border: 1px solid #eee;
      border-radius: 3px;
      padding: 2px;
      margin: -2px 0 0 -2px;
    }
  }
  .task__expiration{
    // display: flex;
    align-items: center;
    margin:0;
    &.red{
      color: #CF0000;
      .task__expiration__icon{
        background-color: #CF0000;
      }
    }
    &.orange{
      color: #FF8B21;
      .task__expiration__icon{
        background-color: #FF8B21;
      }
    }
    &.green{
      color: #1DA332;
      .task__expiration__icon{
        background-color: #1DA332;
      }
    }
    .task__expiration__icon{
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      background-color: #9f9f9f;
      -webkit-mask:  url(../assets/icons/tasks/clock.svg) no-repeat 50% 50%;
      mask: url(../assets/icons/tasks/clock.svg) no-repeat 50% 50%;
      margin-right: 5px;
    }
    .task__date{
      font-family: 'Open Sans', 'Helvetica';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: 0px;
      text-align: left;
    }
    .task__expiration__date{
      font-family: 'Open Sans', 'Helvetica';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
    }
    input[type=checkbox]{
      margin: 0 0 0 2px;
    }
  }
  .popup{
    position: absolute;
    right: 0;
    z-index: 1;
    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    padding: 5px;
  }
  .task__members{
    text-align: right;
    margin-top: 5px;
    .task__member{
      display: inline-block;
      vertical-align: top;
      width: 26px;
      height: 26px;
      margin: 5px 0 0 5px;
      cursor:pointer;
      border-radius: 50%;
      overflow: hidden;
      background: #eee;
      .task__member__image{
        width: 100%;
      }
      span{
        display: inline-block;
        vertical-align: top;
        width: 24px;
        text-align: center;
        font-size: 28px;
        line-height: 24px;
        color: #9f9f9f;
        border: 1px solid #9f9f9f;
        border-radius: 50%;
      }
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        background: none;
      }
    }
  }
  .strong {
    font-weight: bold;
  }
}
</style>
