<template>
    <header id="header">
        <div class="header__inner">
            <div class="header__top">
                <div class="header__content">
                    <img class="header__logo" :src="require('@/assets/logo.png')" alt="Doers DF" title="Doers DF" />
                    <router-link to="/" class="header__title">TRELLO DASHBOARD</router-link>
                    <select @change="filterOrganization">
                        <option></option>
                        <option v-for="organization in this.$store.state.organizations" v-bind:key="organization.id" :value="organization.id">{{organization.displayName ? organization.displayName : organization.id}}</option>
                    </select>
                </div>
            </div>
            <div class="header__background-wrapper">
                <div class="header__middle">
                    <div class="header__content">
                        <div class="header__search-bar">
                            <SearchBar class="header__search"></SearchBar>
                        </div>
                        <div class="header__weeks-selector">
                            <WeekSelector />
                        </div>
                        <div class="header__login">
                            <Login />
                        </div>
                    </div>
                </div>
                <div class="header__bottom">
                    <div class="header__content">
                        <div class="header__info-week">
                            <p class="header__past-week">Anteriores</p>
                            <p class="header__day">Lunes</p>
                            <p class="header__day">Martes</p>
                            <p class="header__day">Miércoles</p>
                            <p class="header__day">Jueves</p>
                            <p class="header__day">Viernes</p>
                            <p class="header__next-week">Próxima semana</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue'
import WeekSelector from '@/components/WeekSelector.vue'
import Login from '@/components/Login.vue'

export default {
  name: 'Header',
  components: {
    SearchBar,
    WeekSelector,
    Login
  },
  methods: {
    filterOrganization (e) {
      this.$store.commit('FILTER', e.target.value)
    }
  }
}
</script>

<style lang="scss">
#header{
    top: -158px;
    position: sticky;
    z-index: 1;
    .header__top,
    .header__middle,
    .header__bottom{
        .header__content{
            max-width: 1720px;
            width: 95%;
            margin: 0 auto;
        }
    }

    .header__top{
        background: #333333;
        padding: 12px 0;
        .header__content{
            display: flex;
            align-items: center;
            .header__logo{
                object-fit: contain;
                margin-right: 32px;
            }
            .header__title{
                font-family: 'Open Sans', 'helvetica', 'sans-serif';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: left;
                color: #fff;
                text-decoration: none;
                text-transform: uppercase;
                display: flex;
                align-items: flex-end;
                height: 24px;

                &:hover{
                    text-decoration: underline;
                }
            }
            select {
                margin-left: auto;
            }
        }
    }

    .header__background-wrapper{
        background-image: url(../assets/background-header.png);
        background-position: left top;
        background-repeat: repeat;
        background-size: auto 100%;

        .header__middle{
            padding-top: 9px;
            padding-bottom: 17px;
            .header__content{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .header__search-bar,
                .header__weeks-selector,
                .header__login{
                    flex: 1 1;
                    max-width: 100%;
                }
                .header__search-bar{
                    flex-basis: 370px;
                }
                .header__weeks-selector{
                    flex-basis:auto;
                }
                .header__login{
                    flex-basis: 370px;
                }
            }
        }
        .header__bottom{
            .header__content{
                .header__info-week{
                    display: flex;
                    justify-content: center;
                    align-items:center;
                    .header__past-week,
                    .header__day,
                    .header__next-week {
                        flex: 1 1;
                        font-family: 'Open Sans', 'Helvetica';
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 0px;
                        text-align: center;
                        color: #FFF;
                        margin-right: 15px;
                        padding-right: 15px;
                    }
                    .header__next-week{
                        padding-left: 15px;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
</style>
