<template>
  <div class="home">
    <loading v-model:active="isLoading" />
    <Header />
    <div class="boards-collection">
      <Panel :boards="this.$store.getters.allBoardsSorted('dateLastActivity')" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Panel from '@/components/Panel.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'home-view',
  components: {
    Header,
    Loading,
    Panel
  },
  data () {
    return {
      boardName: '',
      isLoading: false
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('getUser')
    this.$store.dispatch('getBoards').then(() => { this.isLoading = false })
  },
  created () {
    document.title = 'Doers DF | Trello'
  }
}

</script>

<style lang="scss">
.home{
  .boards-collection{
    max-width: 1720px;
    width: 95%;
    margin: 0 auto;
  }
}
</style>
