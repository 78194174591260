<template>
  <section class="column" :id="id">
    <loading v-model:active="isLoading" />
    <p class="column__info">
      <span class="column__day">
        {{ this.day ? moment(this.$store.state.monday).add(this.day - 1, 'd').format('ddd DD') : '' }}
        {{ this.week ? moment(this.$store.state.monday).add(this.week, 'w').format('DD MMM') + ' al ' + moment(this.$store.state.monday).add(this.week, 'w').add(4, 'd').format('DD MMM') : '' }}
      </span>
      <span class="column__total-tasks">{{ this.day ? '(' + this.cards.length + ' tareas)' : '' }}</span>
      <span class="column__add" v-if="this.day" v-on:click="addTask"><span>+</span></span>
    </p>
    <draggable :list="cards" :group="board.id" @add="moveTask" :item-key="board.id">
      <template #item="{element}">
        <Task :task="element" :board="this.board" />
      </template>
    </draggable>
  </section>
</template>

<script>
import moment from 'moment'
import Task from '@/components/Task.vue'
import draggable from 'vuedraggable'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Column',
  data () {
    return {
      isLoading: false
    }
  },
  props: {
    cards: Array,
    day: Number,
    week: Number,
    board: Object
  },
  components: {
    Task,
    Loading,
    draggable
  },
  methods: {
    moment (d, p) {
      return moment(d, p)
    },
    moveTask: function (e) {
      // this.isLoading = true
      this.$store.dispatch('setCard', {
        id: e.item.dataset.id,
        due: this.day ? moment(this.$store.state.monday).add(this.day - 1, 'd').format('YYYY-MM-DD') : moment(this.$store.state.monday).add(this.week, 'w').format('YYYY-MM-DD')
      }).then(() => {
        this.isLoading = false
      })
    },
    addTask: function () {
      this.isLoading = true
      this.$store.dispatch('addCard', {
        idList: this.board.lists[0].id,
        name: '',
        due: this.day ? moment(this.$store.state.monday).add(this.day - 1, 'd').format('YYYY-MM-DD') : moment(this.$store.state.monday).add(this.week, 'w').format('YYYY-MM-DD')
      }).then(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
.column{
  flex: 1 1;
  margin: 10px 15px 12px 0;
  padding: 0 10px 10px 10px;
  background-color: #F1F1F1;
  border-radius: 5px;
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #9f9f9f #d6d6d6;

  display: flex;
  flex-direction: column;

  &:first-child {
    margin-left: 0;
  }
  &:last-child{
    margin-right: 0;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #d6d6d6;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9f9f9f;
    border-radius: 5px;
    border: 0px none #ffffff;
  }

  .column__info{
    margin: 8px 0 20px;
    font-family: 'Open Sans', 'Helvetica';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    flex-basis: auto;

    .column__day{
      font-size: 16px;
      font-weight: bold;
    }

    .column__add{
      span {
        display: inline-block;
        vertical-align: top;
        width: 20px;
        text-align: center;
        font-size: 24px;
        line-height: 20px;
        color: #9f9f9f;
        border: 1px solid #9f9f9f;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 0 0 5px;
      }
    }
  }
  .column__tasks__list{
    flex: auto;
  }
  > div{
    height: 100%;
  }

}
</style>
