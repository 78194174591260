<template>
    <div class="search-bar">
        <span class="search-bar__icon-wrapper">
            <img class="search-bar__icon" :src="require('@/assets/icons/search_icon.png')" />
        </span>
        <input class="search-bar__input" type="search" name="searchbar" v-model="search" />
        <img class="search-bar-clear__icon" :src="require('@/assets/icons/close.svg')" v-on:click="clearSearch" v-if="search" />
    </div>
</template>

<script>
export default {
  name: 'SearchBar',
  computed: {
    search: {
      get () { return this.$store.state.search },
      set (value) { this.$store.commit('SEARCH', value) }
    }
  },
  methods: {
    clearSearch () {
      this.search = ''
    }
  }
}
</script>

<style lang="scss">
.search-bar{
    position: relative;
    .search-bar__icon-wrapper{
        position: absolute;
        display: flex;
        justify-content: center;
        height:100%;
        align-items: center;
        padding: 0;
        margin: 0 15px;
        .search-bar__icon{
            height: 17.5px;
            width: 17.5px;
            object-fit: contain;
            padding: 0;
            margin: 0;
        }
    }
    .search-bar-clear__icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      margin-left: -26px;
      height: 14px;
      cursor: pointer;
    }
    .search-bar__input{
        height: 34px;
        width: 100%;
        max-width: 360px;
        border-radius: 5px;
        padding-left: 50px;
        outline: 0;
        border: 0;
        &:active{
            outline: 0;
        }
    }
}

</style>
