<template>
  <section :class="{ 'hide': this.board.hide, 'board vld-parent': true }"> <!-- || this.$store.getters.cardsDueCount(this.board.id)-->
      <loading v-model:active="isLoading" :is-full-page="false" />
      <div class="board__title-wrapper" v-on:click="this.toggleBoard">
        <p :key="board.id" class="board__title" :title="board.desc">{{ board.name }}<!-- ({{board.memberships.length}} miembros)--> <img :src="require('@/assets/icons/arrow.svg')" :class="{ 'rotate180': this.board.expand, 'board__arrow': true }" /></p>
        <div v-if="this.board.expand" v-on:click.stop="this.toggleBoard">
          <label v-for="list in board.lists" :key="list.id"><input type="checkbox" :value="list.id" :checked="list.selected" @change="filterList" />{{list.name}}<span class="checkmark"></span></label>
        </div>
        <div class="board__view" v-if="!this.board.expand" v-on:click.stop="hideBoard"><img :src="require('@/assets/icons/' + (this.board.hide ? 'view' : 'hide') + '.svg')" /></div>
      </div>
      <div :class="{ 'board__columns': true, 'hide': !this.board.expand }">
        <Column class="board__column board__column--prev" :board="board" :cards="this.$store.getters.cardsPrev(board.id)" :week=-1 />
        <Column class="board__column board__column--monday" :board="board" :cards="this.$store.getters.cardsDay1(board.id)" :day=1 />
        <Column class="board__column board__column--tueday" :board="board" :cards="this.$store.getters.cardsDay2(board.id)" :day=2 />
        <Column class="board__column board__column--wednesday" :board="board" :cards="this.$store.getters.cardsDay3(board.id)" :day=3 />
        <Column class="board__column board__column--thursday" :board="board" :cards="this.$store.getters.cardsDay4(board.id)" :day=4 />
        <Column class="board__column board__column--friday" :board="board" :cards="this.$store.getters.cardsDay5(board.id)" :day=5 />
        <Column class="board__column board__column--next" :board="board" :cards="this.$store.getters.cardsNext(board.id)" :week=1 />
      </div>
  </section>
</template>

<script>
import Column from '@/components/Column.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Board',
  components: {
    Column,
    Loading
  },
  data () {
    return {
      init: false,
      isLoading: false
    }
  },
  props: {
    board: Object
  },
  created () {
    if (this.board.idOrganization && !this.$store.getters.getOrganization(this.board.idOrganization)) {
      this.$store.dispatch('getOrganization', this.board.idOrganization)
    }
  },
  methods: {
    hideBoard () {
      this.$store.commit('SET_BOARDHIDE', this.board.id)
    },
    toggleBoard () {
      this.$store.commit('SET_BOARDTOGGLE', this.board.id)

      if (this.board.expand && !this.init) {
        this.isLoading = true
        this.$store.dispatch('getCards', this.board.id).then((response) => {
          this.init = this.$store.getters.cardsShowCount(this.board.id)

          this.board.memberships.map(i => i.idMember).forEach((id, idx) => {
            if (!this.$store.getters.getMember(id)) {
              this.$store.dispatch('getMember', id)
            }
          })

          setTimeout(() => {
            this.$store.dispatch('getLists', this.board.id).then(() => {
              this.board.lists.forEach(list => {
                if (list.name !== 'Done' && list.name !== 'Closed') list.selected = true
              })

              this.isLoading = false
            })
          }, 500)
        })
      }
    },
    filterList (e) {
      this.board.lists.forEach(list => {
        if (list.id === e.target.value) {
          list.selected = e.target.checked
        }
      })
    }
  }
}
</script>

<style lang="scss">
.board{
  transition: all .3s;
  // max-height: 100vh;
  // overflow: hidden;
  &.hide {
    // max-height: 0;
    opacity: .3;
  }
  &:first-child{
    margin-top: 10px;
  }
  .board__title-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    background: #333333CC;
    margin: 0 0 10px;
    .board__title{
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      padding: 5px 8px;
      margin: 0;
      color: #FFF;
    }
    div {
      margin: 0 10px 0 auto;
      font-size: 14px;
      color: white;
      label {
        display: inline-block;
        position: relative;
        padding-left: 22px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 0 10px 0 0;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked ~ .checkmark {
            background-color: #333333CC;
            &:after {
              display: block;
            }
          }
        }
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 15px;
          width: 15px;
          background-color: #333;
          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: 0;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &:hover input ~ .checkmark {
          background-color: #444;
        }
      }
    }
  }
  .board__view {
    display: none;
    img {
      vertical-align: middle;
      filter: invert(100%);
      height: 20px;
      cursor: pointer;
    }
  }
  &:hover {
    .board__view {
      display: block;
    }
  }
  .board__columns{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: all 1s;
    max-height: 100vh;
    overflow: hidden;
    &.hide {
      max-height: 0;
    }
  }
  .board__arrow{
    vertical-align: middle;
    margin: 0 0 0 10px;
    &.rotate180{
      transform: rotate(180deg);
    }
  }
}
</style>
