<template>
    <div class="week-sel">
        <i class="week-sel__arrow week-sel__arrow--prev" v-on:click="this.$store.dispatch('prevWeek')"></i>
        <p class="week-sel__week">{{moment(this.$store.state.monday).format('DD MMM')}} al {{moment(this.$store.state.friday).format('DD MMM')}}</p>
        <i class="week-sel__arrow week-sel__arrow--next" v-on:click="this.$store.dispatch('nextWeek')"></i>
    </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WeekSelector',
  methods: {
    moment (d, p) {
      return moment(d, p)
    }
  }
}
</script>

<style lang="scss">
.week-sel{
    display: flex;
    justify-content: center;
    align-items: center;

    .week-sel__arrow{
        width: 33px;
        height: 33px;
        display: block;
        background-image: url(../assets/icons/arrow_week_selector.svg);
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        background-position: center;
        margin: 10px;
        cursor: pointer;

        &.week-sel__arrow--prev{
            transform: rotate(180deg);
        }
    }
    .week-sel__week{
        font-family: 'Roboto Slab', 'Helvetica';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0px;
        text-align: center;
        color: #FFF;
        padding: 0 30px 0 30px;
        margin: 0;
    }
}
</style>
