import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
moment.locale('es')

/* ABRAHAM
key: 'a17c9c9fb07a85cc233397bc46c8c5e8',
token: 'ac17d7b752f39326123b00cce5a8061aae50477dbfeb32389468d5d9fd28598a',
*/
/* CRISTIAN
key: 'b1b3e737ddbda71cecd52083bafb66bd',
token: 'c9128bc5b6dd8941ffca78e3a8a6bc2dcb5615ca010145f8e344dd5843bf3328',
*/

const path = location.href.indexOf('localhost') >= 0 ? 'http://trello-weekly-planner' : 'https://trello.doersdf.com'

var todayString = ''
if (!todayString) todayString = moment().format('YYYY-MM-DD')

const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())
if (!params.token && !localStorage.getItem('token')) location.href = path + '/oauth.php' + (location.href.indexOf('localhost') >= 0 ? '?dev' : '')

if (params.token) localStorage.setItem('token', params.token)
var token = params.token || localStorage.getItem('token')

const state = {
  key: 'b1b3e737ddbda71cecd52083bafb66bd',
  token: token,
  user: {},
  organizations: [],
  boards: [],
  boardsArr: [],
  cards: [],
  cardsArr: [],
  members: [],
  today: moment(todayString),
  monday: moment(todayString).day(1).format('YYYY-MM-DD'),
  friday: moment(todayString).day(5).format('YYYY-MM-DD'),
  search: '',
  filter: ''
}

const getters = {
  user: (state) => state.user,
  getUser: (state) => (field) => { return state.user[field] },
  organizations: (state) => () => { return state.boardsArr ? state.boardsArr.filter(b => b.idOrganization).map(b => { return { id: b.idOrganization } }) : [] },
  allBoards: (state) => state.boards,
  allBoardsSorted: (state) => (order) => { return order ? state.boards.sort((a, b) => a[order] < b[order]).sort((a, b) => a.hide === b.hide ? 0 : (a.hide ? 1 : -1)) : state.boards },
  allCards: (state) => state.cards,
  cardsShowCount: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board).length },
  cardsDueCount: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board && c.due).length },
  cardsPrev: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board && (c.due === null || isBeforeThisWeek(c.due, state.monday))) },
  cardsDay1: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board && (c.due && isForThisWeek(c.due, state.monday, 0))) },
  cardsDay2: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board && (c.due && isForThisWeek(c.due, state.monday, 1))) },
  cardsDay3: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board && (c.due && isForThisWeek(c.due, state.monday, 2))) },
  cardsDay4: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board && (c.due && isForThisWeek(c.due, state.monday, 3))) },
  cardsDay5: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board && (c.due && isForThisWeek(c.due, state.monday, 4))) },
  cardsNext: (state) => (board) => { return state.cards.filter(c => !c.closed && c.idBoard === board && (c.due && isForNextWeekOrAfter(c.due, state.monday))) },
  getOrganization: (state) => (id) => { return state.organizations.find(o => o.id === id) || null },
  getMember: (state) => (id) => { return state.members.find(c => c.id === id) || null }
}

const mutations = {
  SET_USER (state, data) {
    state.user = data
  },
  SET_ORGANIZATIONS (state, data) {
    state.organizations = data
  },
  SET_ORGANIZATION (state, data) {
    if (data.id) {
      const index = state.organizations.findIndex(e => e.id === data.id)
      if (index === -1) {
        state.organizations.push(data)
      } else {
        state.organizations[index] = data
      }
    }
  },
  SET_BOARDS (state, data) {
    state.boards = data
    state.boardsArr = state.boards
  },
  SET_CARDS (state, data) {
    state.cards = state.cards.concat(data)
    state.cardsArr = state.cards
  },
  SET_CARD (state, data) {
    const index = state.cards.findIndex(e => e.id === data.id)
    if (index === -1) {
      state.cards.push(data)
    } else {
      state.cards[index] = data
    }
  },
  SET_MEMBER (state, data) {
    const index = state.members.findIndex(e => e.id === data.id)
    if (index === -1) {
      state.members.push(data)
    } else {
      state.members[index] = data
    }
  },
  SET_BOARDMEMBERS (state, data) {
    console.log(state, data)
    // const index = state.board.findIndex(e => e.id === data.id)
    // if (index === -1) {
    //   state.members.push(data)
    // } else {
    //   state.members[index] = data
    // }
  },
  SET_BOARDLISTS (state, data) {
    const index = state.boards.findIndex(e => e.id === data[0].idBoard)
    if (index === -1) {
      // state.boards.push(data)
    } else {
      state.boards[index].lists = data
    }
  },
  SET_BOARDTOGGLE (state, data) {
    const index = state.boards.findIndex(e => e.id === data)
    if (index === -1) {
      // state.boards.push(data)
    } else {
      state.boards[index].expand = !state.boards[index].expand
    }
  },
  SET_BOARDHIDE (state, data) {
    const index = state.boards.findIndex(e => e.id === data)
    if (index === -1) {
      // state.boards.push(data)
    } else {
      state.boards[index].hide = !state.boards[index].hide
    }
  },
  SET_BOARDSSHOWALL (state) {
    state.boards.forEach(b => delete b.hide)
  },
  SET_MONDAY (state, v) {
    if (!todayString) todayString = moment().format('YYYY-MM-DD')
    state.today = moment(todayString).add(v * 7, 'd')
    todayString = state.today.format('YYYY-MM-DD')
    state.monday = moment(todayString).day(1).format('YYYY-MM-DD')
    state.friday = moment(todayString).day(5).format('YYYY-MM-DD')
  },
  SEARCH (state, v) {
    console.log(v)
    state.search = v
    if (v) state.boards = state.boardsArr.filter(b => b.name.toLowerCase().indexOf(v.toLowerCase()) >= 0)
    else state.boards = state.boardsArr
    // if (v && v.length > 2) state.cards = state.cardsArr.filter(c => c.name.toLowerCase().indexOf(v.toLowerCase()) >= 0)
    // else state.cards = state.cardsArr
  },
  FILTER (state, v) {
    if (v) state.boards = state.boardsArr.filter(b => b.idOrganization === v)
    else state.boards = state.boardsArr
  }
}

const actions = {
  getUser (context) {
    return axios.get('https://api.trello.com/1/members/me?key=' + state.key + '&token=' + state.token)
      .then(response => {
        context.commit('SET_USER', response.data)
      }).catch(err => {
        console.log(err)
        localStorage.clear()
        location.refresh()
      })
  },
  getOrganizations (context) {
    return axios.get(path + '/api.php?organizations&key=' + state.key + '&token=' + state.token)
      .then(response => {
        context.commit('SET_ORGANIZATIONS', response.data)
      })
  },
  getOrganization (context, id) {
    context.commit('SET_ORGANIZATION', { id })
    return axios.get(path + '/api.php?organizations=' + id + '&key=' + state.key + '&token=' + state.token)
      .then(response => {
        context.commit('SET_ORGANIZATION', response.data)
      })
  },
  getBoards (context) {
    return axios.get(path + '/api.php?boards&key=' + state.key + '&token=' + state.token)
      .then(response => {
        context.commit('SET_BOARDS', response.data)
      })
  },
  getCards (context, idBoard) {
    return new Promise((resolve, reject) => {
      axios.get(path + '/api.php?cards&board=' + idBoard + '&key=' + state.key + '&token=' + state.token)
        .then(response => {
          context.commit('SET_CARDS', response.data)
          resolve(response.data)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  getCard (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(path + '/api.php?cards=' + id + '&key=' + state.key + '&token=' + state.token)
        .then(response => {
          context.commit('SET_CARD', response.data)
          resolve()
        }).catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  getMember (context, id) {
    context.commit('SET_MEMBER', { id })
    return axios.get(path + '/api.php?members=' + id + '&key=' + state.key + '&token=' + state.token)
      .then(response => {
        context.commit('SET_MEMBER', response.data)
      })
  },
  getMembers (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(path + '/api.php?boardmembers=' + id + '&key=' + state.key + '&token=' + state.token)
        .then(response => {
          context.commit('SET_BOARDMEMBERS', response.data)
          resolve()
        }).catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  getLists (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(path + '/api.php?boardlists=' + id + '&key=' + state.key + '&token=' + state.token)
        .then(response => {
          context.commit('SET_BOARDLISTS', response.data)
          resolve()
        }).catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  prevWeek (context) {
    context.commit('SET_MONDAY', -1)
  },
  nextWeek (context) {
    context.commit('SET_MONDAY', 1)
  },
  setCard (context, data) {
    return new Promise((resolve, reject) => {
      axios.put(path + '/api.php?cards=' + data.id + '&key=' + state.key + '&token=' + state.token, data)
        .then(response => {
          context.commit('SET_CARD', response.data)
          resolve()
        }).catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
  addCard (context, data) {
    return new Promise((resolve, reject) => {
      axios.post(path + '/api.php?cards&key=' + state.key + '&token=' + state.token, data)
        .then(response => {
          context.commit('SET_CARD', response.data)
          resolve()
        }).catch(err => {
          console.log(err)
          reject(err)
        })
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

const isBeforeThisWeek = (due, monday) => {
  return moment(due).isBetween(moment(monday).add(-7, 'd'), moment(monday), 'day', '[)')
}

const isForThisWeek = (due, monday, dayOfWeek = 0) => {
  if (dayOfWeek) {
    return moment(due).isSame(moment(monday).add(dayOfWeek, 'd'), 'day')
  } else {
    return moment(due).isSame(moment(monday), 'day')
  }
}

const isForNextWeekOrAfter = (due, monday) => {
  return moment(due).isBetween(moment(monday).add(4, 'd'), moment(monday).add(11, 'd'), 'day', '(]')
}
